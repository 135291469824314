<template>
  <div class="auther-picture-container">
    <div class="heade">
      <div class="sign">
        <router-link to="/"
          ><img :src="require('@/assets/imgs/zwzsw.png')" alt="" />
        </router-link>
        <router-link to="/"
          ><img :src="require('@/assets/imgs/bjlogo.png')" alt="" />
        </router-link>
        <span class="name">{{name+'的图集'}}</span>
      </div>
    </div>
    <div class="picture-content">
      <div class="contents">
        <div class="box" ref="refBox">
          <div
            class="imgs"
            :style="{ width: imgList.length * imgWidth + 'px', marginLeft }"
          >
            <div class="all" v-for="item in imgList" :key="item.id">
              <img :src="$global.imgUrl + item.img" alt="" />
              <p>
                {{ item.imgDescribe }}
              </p>
            </div>
          </div>
          <div class="direction">
            <span @click="handleTurn('left')"></span>
            <span @click="handleTurn('right')"></span>
          </div>
        </div>
        <div class="circle">
          <span>{{ curNum + 1 }}/{{ imgList.length }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.spaceScholarGallery({
      pageNo: 1,
      pageSize: 10,
      userId: this.$route.query.id,
    });
  },
  data() {
    return {
      imgList: [],
      curNum: 0,
      imgWidth: 0,
      name: '',
    };
  },
  mounted() {
    this.imgWidth = this.$refs.refBox.clientWidth;
  },
  computed: {
    marginLeft() {
      return -this.curNum * this.imgWidth + "px";
    },
  },
  methods: {
    async spaceScholarGallery(data) {
      const resp = await this.$apis.autherServe.spaceScholarGallery(data);
      this.name = resp.content.authorName
      this.imgList = resp.content.gallery.list;
    },
    handleTurn(type) {
      if (type === "left") {
        this.curNum--;
      } else {
        this.curNum++;
      }
      if (this.curNum < 1) {
        this.curNum = 0;
      }
      if (this.curNum > this.imgList.length - 1) {
        this.curNum = this.imgList.length - 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.auther-picture-container {
  width: 100%;
  height: 100%;
  .heade {
    height: 99px;
    width: 100%;
    background-color: #000;
    padding-left: 110px;
    padding-top: 29px;
    .sign {
      display: flex;
      align-items: center;
      img {
        width: 176px;
        height: 39px;
        margin-right: 11px;
      }
      .name {
        font-size: 18px;
        color: #fefefe;
        margin-left: 60px;
      }
    }
  }
  .picture-content {
    width: 100%;
    height: calc(100% - 99px);
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    .contents {
      width: 1040px;
      margin: 60px auto;
      color: #fff;
      position: relative;
      .box {
        overflow: hidden;
        height: 800px;
        .imgs {
          height: 600px;
          transition: all 0.3s;
          display: flex;
          .all {
            width: 1040px;
            height: 100%;
            p {
              margin-top: 80px;
            }
          }
          img {
            width: 1040px;
            height: 100%;
            border-radius: 12px;
            background: #e6e6e6;
            border: 1px solid #999999;
          }
        }
      }
      .direction {
        position: absolute;
        top: 40%;
        width: 1040px;
        height: 94px;
        transform: translateY(-100%);
        span {
          content: "";
          display: block;
          width: 33px;
          height: 94px;
          position: absolute;
          cursor: pointer;
        }
        span:first-child {
          background-image: url("~@/assets/qietu/left1.png");
          left: -50px;
        }
        span:last-child {
          background-image: url("~@/assets/qietu/right1.png");
          right: -50px;
        }
      }
      .circle {
        margin: 31px 0 38px 0;
        position: absolute;
        left: 0;
        bottom: 100px;
        right: 0;
        span:first-child {
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          text-align: center;
        }
        .origin {
          float: right;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
